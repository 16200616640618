import { connect } from 'react-redux';
import React, { Component } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import { formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Button, Icon, Modal } from 'semantic-ui-react';
import {
  bankDetails,
  createFiatDepositRequestPromiseCreator,
  getDepositBankDetails,
  getDepositReferenceCode,
  referenceCode,
  clearReferenceCode,
} from '../../reducers/fundTransfersReducer';
import {
  getActiveAccount,
  getActiveAccountFD,
} from '../../reducers/activeAccountSelectors';
import { getFiatAssetTypes } from '../../reducers/assetTypesReducer';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedBankAccounts,
  getLinkedAccountLabel,
} from '../../reducers/linkedAccountsReducer';
import FiatDeposit from '../../components/FundTransfers/FiatDeposit';
import { formatAssetTypesForFundsTransfer } from '../../utils/methods';
import history from '../../constants/history';
import payMethods from '../../constants/payMethods';
import { trackEvent } from '../../common/tracking';
import gaCategories from '../../constants/ga-categories';

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createFiatDepositRequestPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      bankDetails,
      fetchLinkedMemberAssetAccounts,
      clearReferenceCode,
      referenceCode,
    },
    dispatch,
  ),
});

const selector = formValueSelector('fiatDeposit');

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  amount: selector(state, 'amount'),
  assetType: selector(state, 'assetType'),
  assetTypes: getFiatAssetTypes(state),
  bankDetailsList: getDepositBankDetails(state),
  bankDetailsLoading: createLoadingSelector(['BANK_DETAILS'])(state),
  fundsDesignation: selector(state, 'fundsDesignation'),
  linkedBankAccounts: getLinkedBankAccounts(state),
  accountsLoading: createLoadingSelector(['LINKED_BANK_ACCOUNTS'])(state),
  refCode: getDepositReferenceCode(state),
  refCodeLoading: createLoadingSelector(['REFERENCE_CODE'])(state),
  activeAccountFD: getActiveAccountFD(state),
});

class FiatDepositContainer extends Component {
  state = { modalOpen: false };

  componentDidMount = () => {
    trackEvent(gaCategories.DEPOSIT_WIRE, 'depositWireTab');
  };

  componentWillUnmount = () => {
    this.props.clearReferenceCode();
  };

  handleClose = () => {
    const { account } = this.props;
    this.setState({ modalOpen: false });
    history.push(`/accounts/${account.accountId}/movements`);
  };

  bankAccountOptions = (linkedBankAccounts = []) =>
    linkedBankAccounts.map((account) => ({
      key: account.hashId,
      value: account.id,
      text: getLinkedAccountLabel(account),
    }));

  render = () => (
    <React.Fragment>
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="small"
        closeIcon
        centered={false}
      >
        <Modal.Content className="text-center">
          <h2>Your deposit indication has been submitted</h2>
          <p>
            Remember, you still need to initiate a transaction with your
            financial institution in order to fund your Cboe Clear account.
          </p>
          <Button color="primary" onClick={this.handleClose}>
            <Icon name="checkmark" /> Continue
          </Button>
        </Modal.Content>
      </Modal>
      <FiatDeposit
        {...this.props}
        bankDetails={this.props.bankDetails}
        createFiatDepositRequest={(payload) => {
          trackEvent(gaCategories.DEPOSIT_WIRE, 'submit');
          this.props
            .createFiatDepositRequestPromiseCreator({
              ...payload,
              accountId: this.props.accountId,
              payMethod: payMethods.FWT,
            })
            .then(() => this.setState({ modalOpen: true }));
        }}
        currencyOptions={formatAssetTypesForFundsTransfer({
          ...this.props,
          skipBalancesCheck: true,
        })}
        handleChange={this.handleChange}
        linkedBankAccounts={this.bankAccountOptions(
          this.props.linkedBankAccounts,
        )}
        linkedBankAccountsLoading={this.props.accountsLoading}
        refCode={this.props.refCode}
        referenceCode={this.props.referenceCode}
      />
    </React.Fragment>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FiatDepositContainer);
