import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import colors from '../../constants/colors';
import StyledButton from '../../common/StyledButton';
import { userFirstTraded, userFirstFunded } from '../../common/glowRules';
import OrderEntryItem from './OrderEntryItem';
import { Icon } from 'semantic-ui-react';
import { get } from 'lodash';
import { getLatestPrices, TOP_OF_BOOK } from '../../reducers/orderEntryReducer';
import { formatFiat } from '../../utils/methods';
import { USD } from '../../constants/assetTypes';
import { createLoadingSelector } from 'erisxkit/client';
import { getSelectedPartyId } from '../../reducers/userReducer';
import { getActiveOrOnlyAccount } from '../../reducers/activeAccountSelectors';
import { BALANCES } from '../../reducers/balancesReducer';

const BUY = 0;
const SELL = 1;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  font-weight: bold;
  background-color: ${colors.NAVY};
  opacity: ${({ isOverlayActive }) => isOverlayActive && 0.5};
  pointer-events: ${({ isOverlayActive }) => isOverlayActive && 'none'};
`;
FormWrapper.displayName = 'FormWrapper';

const ErrorText = styled.div`
  position: absolute;
  padding: 20px 0px;
  margin: 8px;
  font-size: 16px;
  border-left: 5px solid ${colors.RED_ERROR};
  color: ${colors.WHITE};
  background-color: ${colors.CBOE_CLEAR};
  display: ${({ isOverlayActive }) => !isOverlayActive && 'none'};
  z-index: 1;
  text-align: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
`;
ErrorText.displayName = 'ErrorText';

const HeaderContainer = styled.div`
  display: flex;
  color: ${colors.GREEN};
  justify-content: center;
  padding: 10px 0px;
`;
HeaderContainer.displayName = 'HeaderContainer';

const Header = styled.h2`
  font-size: 1.71428571rem;
  color: ${colors.GREEN};
  margin-bottom: 0px;
`;
Header.displayName = 'Header';

const ExchangeIcon = styled(Icon)`
  padding-top: 5px;
  margin-left: 10px !important;
`;
ExchangeIcon.displayName = 'ExchangeIcon';

const StyledTabs = styled(Tabs)`
  background-color: ${colors.NAVY_2};
  .MuiTabs-flexContainer {
    justify-content: space-between;
  }
`;
StyledTabs.displayName = 'StyledTabs';

const StyledTabBuy = styled(Tab)`
  && {
    font-size: 17px;
    border-bottom: 1px solid none;
    min-width: 0;
    background-color: rgb(0, 44, 70);
  }
`;

const StyledTabSell = styled(Tab)`
  && {
    font-size: 17px;
    border-bottom: 1px solid none;
    min-width: 0;
    background-color: rgb(50, 38, 66);
  }
`;

StyledTabBuy.displayName = 'StyledTabBuy';
StyledTabSell.displayName = 'StyledTabSell';

const Content = styled.div`
  padding: 20px 10px;
`;
Content.displayName = 'Content';

const ConfirmOrder = styled(StyledButton)`
  width: 100%;
  margin: 30px 0px;
`;
ConfirmOrder.displayName = 'ConfirmOrder';

const OrderFooter = styled.p`
  font-size: 12px;
  color: ${(props) => (props.error ? colors.RED_ERROR : colors.GRAY_2)};
`;
OrderFooter.displayName = 'OrderFooter';

const ResetOrder = styled.a`
  font-size: 14px;
  color: ${colors.RED_ERROR};
  display: flex;
  justify-content: center;
`;
ResetOrder.displayName = 'ResetOrder';

const Item = styled(OrderEntryItem)`
  margin-bottom: 20px;
`;
Item.displayName = 'Item';

const BestPrice = styled.p`
  margin: 0px 0px 20px 0px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.GRAY_2};
  text-align: center;
`;
BestPrice.displayName = 'BestPrice';

const ModalOrderEntryWidget = ({
  assetType,
  assetChanged,
  handleChange,
  amount,
  isBuyDisabled,
  submitClicked,
  getOrderQty,
  marketUnavailable,
  side,
  user,
  currency,
  activeIndex,
  tabChanged,
  className,
  latestPrices,
  loadingTopOfBook,
  loadingBalances,
  quoteCurrency,
  quoteCurrencyOptions,
  handleChangeQuoteCurrency,
  baseCurrencyOptions,
  handleChangeBaseCurrency,
  baseCurrency,
  resetForm,
  partyId,
  selectedAccountId,
  amountError,
  errorText,
}) => {
  const orderType = activeIndex === BUY ? 'buy' : 'sell';
  const isLoading = loadingTopOfBook || loadingBalances;

  const shouldConfirmPulse = () =>
    assetType && !amount && !userFirstTraded(user) && userFirstFunded(user);

  const getOrderFooterMessage = () => {
    if (!partyId)
      return 'This account is not enabled for trading.  Please select another account to use Quick Trade.';

    if (marketUnavailable)
      return 'Market currently not available. Please choose another asset.';

    if (isBuyDisabled())
      return `An order will be placed to ${orderType} the selected currency at the best available price. Please be advised that you may not cancel a trade once confirmed as executed.`;

    return `An order will be placed to ${orderType} ${getOrderQty()} ${currency} based on the best available price. Please be advised that you may not cancel a trade once confirmed as executed.`;
  };

  const getSymbol = () => {
    if (activeIndex === BUY) return 'USD';
    return null;
  };

  const latestPrice = formatFiat(get(latestPrices, assetType, null), USD);

  const getLoadingText = () =>
    loadingTopOfBook ? 'Getting best price...' : 'Fetching balances...';
  const getMidPriceText = () =>
    latestPrice ? `Mid-Price: ${latestPrice}` : 'Price not available';

  const midPriceText = isLoading ? getLoadingText() : getMidPriceText();

  return (
    <Wrapper>
      <ErrorText isOverlayActive={!partyId}>
        {!selectedAccountId ? (
          'Please select an account to trade.'
        ) : (
          <Fragment>
            Trading for this account is not configured. Please contact{' '}
            <a href="mailto:clearus.membership@cboe.com">
              clearus.membership@cboe.com{' '}
            </a>
            or select another account.
          </Fragment>
        )}
      </ErrorText>
      <FormWrapper className={className} isOverlayActive={!partyId}>
        <HeaderContainer>
          <Header>Quick Trade</Header>
          <ExchangeIcon name="exchange" size="large" />
        </HeaderContainer>
        <StyledTabs
          value={activeIndex}
          activeIndex={activeIndex}
          onChange={(e, activeIndex) => tabChanged(e, { activeIndex })}
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              background: activeIndex === SELL && colors.RED_SELL_BUTTON,
            },
          }}
        >
          <StyledTabBuy label="BUY" />
          <StyledTabSell label="SELL" />
        </StyledTabs>
        <Content>
          <BestPrice>{midPriceText}</BestPrice>
          <Item
            name="Asset"
            id="widget-asset"
            single
            dropdown
            onChange={handleChangeBaseCurrency}
            options={baseCurrencyOptions}
            value={baseCurrency}
            disabled={isEmpty(baseCurrencyOptions) || isLoading}
          />
          <Item
            name="Amount"
            symbol={getSymbol()}
            input
            id="widget-amount"
            onChange={handleChange}
            options={quoteCurrencyOptions}
            value={amount}
            selectOnChange={handleChangeQuoteCurrency}
            selectValue={quoteCurrency}
            error={amountError}
            errorText={errorText}
            disabled={!baseCurrency || isLoading}
          />
          <ConfirmOrder
            text={`PREVIEW ${orderType.toUpperCase()}`}
            primary={activeIndex === BUY}
            sell={activeIndex === SELL}
            pulse={shouldConfirmPulse()}
            onClick={submitClicked}
            disabled={isBuyDisabled() || isLoading}
          />
          <OrderFooter error={marketUnavailable}>
            {getOrderFooterMessage()}
          </OrderFooter>
          <ResetOrder onClick={resetForm}>Reset</ResetOrder>
        </Content>
      </FormWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  latestPrices: getLatestPrices(state),
  loadingTopOfBook: createLoadingSelector([TOP_OF_BOOK])(state),
  loadingBalances: createLoadingSelector([BALANCES])(state),
  partyId: getSelectedPartyId(state),
  selectedAccountId: getActiveOrOnlyAccount(state),
});

export default connect(mapStateToProps, null)(ModalOrderEntryWidget);
