export const light = {
  primary: '#F5F5F5',
  primary2: '#FFFFFF',
  primary3: '#95959F',
  primary4: '#CFD2D9',
  primary5: '#F9FCFE',
  primary6: '#E0E0E0',
  primary7: '#4F4F4F',
  primary8: '#b3b3b8',
  primary9: '#f9f1f1',
  primary10: '#9696a0',
  primary11: '#353c40',
  primary12: '#818181',
  primary13: '#D4D4D5',
  primary14: '#D4D4D5',
  primary15: '#F2F7FB',
  secondary: '#205080',
  secondary2: '#15446E',
  secondary3: '#575C76',
  secondary4: '#002c46',
  secondary5: '#00a0ff21',
  secondary6: '#1d2952',

  accent: '#00ebb9',
  accent2: '#49b29d',
  accent3: '#00d2a5',
  accent4: '#00b891',
  accent5: '#1e514f',
  accent6: '#a0d3c9',
  accent7: '#719e9c',
  accent8: '#00c89d',
  accent9: '#a2eada',
  accent10: '#c1f1e6',

  accentNegative: '#f34a49',
  accentNegative2: '#f33e3d',
  accentNegative3: '#e92928',
  accentNegative4: '#ffdada',
  accentNegative5: '#d79f9f',
  accentNegative6: '#dd4747',

  black: '#000000',
  black2: '#000000de',
};

export const dark = {
  primary: '#002c46',
  primary2: '#0c375a',
  primary3: '#205080',
  primary4: '#15446e',
  primary5: '#003c60',
  primary6: '#004c79',
  primary7: '#74BCF6',
  primary8: '#0C3455',
  primary9: '#305573',
  primaryCboeClear: '#1D2952',

  secondary: '#dcdce1',
  secondary2: '#FFFFFF',
  secondary3: '#9696a0',
  secondary4: '#2c3a4a',

  accent: '#00ebb9',
  accent2: '#49b29d',
  accent3: '#00d2a5',
  accent4: '#00b891',
  accent5: '#1e514f',
  accent6: '#4183C4',
  accent7: '#00c89d',
  accent8: '##0fffcc',
  accentNegative: '#ff0000',
  accentNegative2: '#d50908',

  tradingSell: '#ff274b',
  tradingSell2: '#992848',
  tradingSell3: '#b72a42',
  tradingSell4: '#cf2b47',
};
