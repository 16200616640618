import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Button, List, Divider } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import * as rules from '../common/forms/rules';
import { renderField } from '../common/forms/components';

const getLabelContent = (e) => {
  if (e === 0) return ''; // Blank password should show no complexity feedback
  if (e < 28) return 'very weak';
  if (e < 35) return 'weak';
  if (e < 59) return 'marginal';
  if (e < 127) return 'strong';
  if (e >= 127) return 'very strong';
};

const Content = styled.div`
  background-color: ${({ theme }) => theme.modal.bg};
  .onboarding-header {
    color: ${({ theme }) => theme.text.header};
  }
  > p {
    color: ${({ theme }) => theme.text.text};
  }
`;

const StyledList = styled(List)`
  && {
    color: ${({ theme }) => theme.text.text};
  }
`;
export class CreateFundingPassword extends PureComponent {
  state = {
    loading: false,
    skipClicked: false,
  };

  static getDerivedStateFromProps = (props, state) => {
    if (!props.loading && state.loading) {
      return { loading: false };
    }
    return null;
  };

  render = () => {
    const {
      handleSubmit,
      loading,
      action,
      storeTxAuthKeyFailed,
      skipFunding,
      invalid,
      entropy,
      isOnboarding,
      isRsbixUser,
    } = this.props;
    return (
      <div className="onboarding-container">
        <Content className="onboarding-content scrollable medium-width">
          <span className="onboarding-header">
            {!isOnboarding && 'Change '}Funding Password
          </span>
          {isOnboarding && (
            <Fragment>
              <p>
                Set up a funding password for your user account. This password
                allows you to securely add bank and crypto currency accounts and
                is required. It should be unique from any password you may use
                to log in.
              </p>
              <p>
                Your funding password is used to protect your assets. To ensure
                the security of your account, we measure the complexity of this
                password using a technique known as{' '}
                <a
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/Password_strength#Entropy_as_a_measure_of_password_strength"
                  rel="noreferrer"
                >
                  entropy
                </a>
                .
              </p>
            </Fragment>
          )}
          {!isOnboarding && (
            <p>
              <b>
                In order to better manage the security of your API keys, we
                highly recommend that you refresh your API keys when you modify
                your funding password or create new keys on a regular basis. API
                keys remain valid until you delete them.
              </b>
            </p>
          )}
          <p>
            {' '}
            Here are some suggestions to ensure you choose a sufficiently
            complex password:{' '}
          </p>
          <StyledList bulleted className="min-reqs">
            <List.Item>At least 12 characters in length</List.Item>
            <List.Item>
              Use a mixture of letters, numbers, and punctuation
            </List.Item>
            <List.Item>
              Do not reuse the same password you have used elsewhere
            </List.Item>
            <List.Item>
              As an alternative, consider using a set of 4 or 5 random words
            </List.Item>
          </StyledList>
          {!isOnboarding && (
            <p>
              If you don't remember your funding password, contact{' '}
              <a href="mailto:clearus.membership@cboe.com">
                clearus.membership@cboe.com
              </a>{' '}
              <span className="nowrap">(+1 (888) 782-7481)</span> to reset it.
            </p>
          )}
          <Form onSubmit={handleSubmit} autoComplete="off">
            {action === 'reset' && (
              <Field
                component={renderField}
                placeholder="Enter old funding password:"
                name="oldPassword"
                id="old-password"
                type="password"
                required
                autocomplete="new-password"
                data-cy="ob-funding-password-field-reset"
              />
            )}
            <Field
              component={renderField}
              placeholder={`Enter ${
                action === 'reset' ? 'new ' : ''
              }funding password:`}
              name="password"
              id="password"
              type="password"
              validate={[rules.required, rules.passwordStrength]}
              required
              label={{
                basic: true,
                content: getLabelContent(entropy),
                className: `password-str ${getLabelContent(entropy)}`,
              }}
              style={{
                className: getLabelContent(entropy),
              }}
              labelPosition="right"
              autocomplete="new-password"
              data-cy="ob-funding-password-field"
            />
            <Field
              component={renderField}
              placeholder={`Confirm ${
                action === 'reset' ? 'new ' : ''
              }funding password:`}
              name="passwordConfirm"
              id="passwordConfirm"
              type="password"
              validate={[rules.required, rules.passwordMatch]}
              required
              autocomplete="new-password"
              data-cy="ob-funding-password-field-repeat"
            />
          </Form>
          <div>
            <Divider hidden />
            <Button
              data-dd-action-name="Submit Funding Password"
              data-cy="ob-funding-password-submit"
              loading={this.state.loading || loading}
              className="top-button submit"
              onClick={() =>
                this.setState({ loading: true }, () => handleSubmit())
              }
              fluid
              primary
              type="submit"
              disabled={
                invalid ||
                this.state.loading ||
                loading ||
                this.state.skipClicked
              }
            >
              Submit
            </Button>
            {action !== 'reset' && storeTxAuthKeyFailed && !isRsbixUser && (
              <Button
                data-dd-action-name="Skip Funding Password"
                loading={this.state.skipClicked}
                disabled={this.state.skipClicked}
                secondary
                fluid
                onClick={() =>
                  this.setState({ skipClicked: true }, () => skipFunding())
                }
              >
                Skip
              </Button>
            )}
          </div>
        </Content>
      </div>
    );
  };
}

CreateFundingPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
};

CreateFundingPassword.defaultProps = {
  invalid: true,
};

export default reduxForm({
  form: 'funding_password',
})(CreateFundingPassword);
