import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import IconsBG from '../../common/components/IconsBG';
import Footer from './Footer';
import history from '../../constants/history';
import colors from '../../constants/colors';

export const AuthMessageType = {
  error: 'error',
  information: 'information',
  warning: 'warning',
  email: 'email_verify',
  ip: 'invalid_ip',
};

export const ERROR_TYPE_STYLES = {
  [AuthMessageType.error]: 'error',
  [AuthMessageType.information]: 'information',
  [AuthMessageType.warning]: 'warning',
  [AuthMessageType.email]: 'information',
  [AuthMessageType.ip]: 'warning',
};

const FULL_ERROR_DETAIL = {
  [AuthMessageType.email]:
    'Open your email inbox and look for an email with the subject of Welcome.  Open the email and click on the link in the email to verify your email address.',
};

const IconMessage = (error_type) => {
  switch (error_type) {
    case AuthMessageType.email:
    case AuthMessageType.information:
      return 'info';
    case AuthMessageType.warning:
      return 'exclamation';
    case AuthMessageType.ip:
      return 'exclamation';
    default:
      return 'close';
  }
};

const getFullErrorDesc = (errorType, errorDesc) =>
  errorType === AuthMessageType.ip
    ? `IP Address [${errorDesc}] is not authorized`
    : errorDesc;

const AuthCustomMessages = ({ error_type, error_description }) => (
  <div>
    <div className={`square s-${ERROR_TYPE_STYLES[error_type]}`}>
      <div>
        <i class={`icon ${IconMessage(error_type)} no-margin`}></i>
      </div>
    </div>
    <div className={`rectangle r-${ERROR_TYPE_STYLES[error_type]}`}>
      <p className="relative-position">
        {getFullErrorDesc(error_type, error_description)}
      </p>
    </div>
  </div>
);

export const AuthMessages = ({
  error_type = AuthMessageType.warning,
  error_description = '',
  message_only = '',
}) => {
  return (
    <>
      <IconsBG />
      <section
        className="content-container"
        style={{ overflow: 'auto', backgroundColor: colors.NAVY }}
      >
        <header id="top-nav" className={'simple'}>
          <div className={`logo-container`}>
            <div role="presentation" className={'logo'} alt="ErisX" />
          </div>
        </header>
        <div className="onboarding-messages">
          <div className="onboarding-content onboarding-content-message auth-messages">
            <div className="logo"></div>
            <div className="onboarding-content-message-main">
              <AuthCustomMessages
                error_type={error_type}
                error_description={error_description}
              />
              {FULL_ERROR_DETAIL[error_type] && (
                <p>{FULL_ERROR_DETAIL[error_type]}</p>
              )}
              {!message_only && (
                <>
                  <Button
                    className="first-button-recomendation onboarding-content-message-button relative-position"
                    onClick={() => {
                      history.push('/login');
                    }}
                    primary
                    content="Back to Login"
                  />
                  <p className="onboarding-content-message-footer">
                    Having Trouble?{' '}
                    <a
                      href="mailto:clearus.membership@cboe.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Contact
                    </a>{' '}
                    our support team
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </section>
    </>
  );
};
