import { get, some } from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Joyride, { STATUS } from 'react-joyride';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { hideModal, showModal } from 'erisxkit/client';
import AssetBalancesContainer from './AssetBalancesContainer';
import {
  getAllMemberAccountsList,
  selectAccount,
} from '../reducers/accountsReducer';
import AccountsHomeHistoryContainer from './AccountsHomeHistoryContainer';
import {
  getDefaultTradingApp,
  getLoggedInUser,
  getShowUserOffboardingModal,
  getShowUserTaxStatementsModal,
  getUserHasFcmMember,
  updateTutorialState,
} from '../reducers/userReducer';
import XTooltip from '../common/components/XTooltip';
import { isViewportLessThan } from '../reducers/uiReducer';
import { usesSSO } from '../utils/methods';
import EnvPromise from '../config/env';
import AccessControl, { checkPermissions } from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';
import {
  accountIsAllowedFutures,
  accountIsAllowedSubExchange,
} from '../utils/permissions';
import accountBalancesMetadata from '../metadata/assetBalancesMetadata';
import {
  API_CREDENTIALS,
  fetchApiCredentialsPromiseCreator,
} from '../reducers/apiCredentialsReducer';
import { DM_OFFBOARDING_MODAL, TAX_STATEMENTS } from '../constants/modalTypes';

const mapStateToProps = (state) => ({
  accounts: getAllMemberAccountsList(state),
  defaultTradingApp: getDefaultTradingApp(state),
  user: getLoggedInUser(state),
  viewportLessThanMedium: isViewportLessThan('medium', state),
  showEStatementModal: getShowUserTaxStatementsModal(state),
  showOffboardingModal: getShowUserOffboardingModal(state),
  hasFcmMember: getUserHasFcmMember(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchApiCredentialsPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      updateTutorialState,
      selectAccount,
      showModal,
      hideModal,
    },
    dispatch,
  ),
});

const joyrideTheme = {
  options: {
    arrowColor: '#15446ed0',
    backgroundColor: '#15446ed0',
    textColor: '#dcdce1',
    primaryColor: '#00ebb9',
    overlayColor: '#00000088',
  },
};

class HomeEMP extends PureComponent {
  componentWillMount = () =>
    EnvPromise.then((env) => {
      const {
        defaultTradingApp,
        viewportLessThanMedium,
        user,
        selectAccount,
        hasFcmMember,
      } = this.props;
      // Only remove currently selected account for DMs
      if (!hasFcmMember) selectAccount('');
      const steps = [
        {
          target: '.main-holdings',
          title: 'Welcome to Cboe Clear!',
          content: (
            <p>
              Thank you for becoming a member of Cboe Clear and joining us on
              our journey to improve the digital asset trading experience. There
              are a couple small steps left to complete your trading access and
              to fund your account.
            </p>
          ),
          placement: 'center',
        },
        ...(checkPermissions(user.uiViews, [
          `${grants.CREATE}:${subjects.DEPOSITS}`,
        ])
          ? [
              {
                target: '#funds-actions-dropdown-button',
                title: 'Fund your account for trading',
                content: (
                  <p>
                    Click Fund and select Deposit from the menu. Then, select
                    the asset you with to deposit.
                  </p>
                ),
              },
            ]
          : []),
        {
          target: viewportLessThanMedium ? '.small-logo' : '.sidebar-account-0',
          title: 'Your Accounts',
          content: (
            <Fragment>
              <p>
                Your home screen shows a summary of balances and recent
                transactions for all accounts.
              </p>
              {viewportLessThanMedium ? (
                <p>
                  Clicking on this logo while in the mobile view will bring up a
                  list of your account(s).
                </p>
              ) : (
                ''
              )}
              <p>
                Click an account to get a detailed view of the balances, recent
                trades, and asset movements for that specific account.
              </p>
            </Fragment>
          ),
        },
        {
          target: viewportLessThanMedium ? '.small-logo' : '.secondary-nav',
          title: 'Help',
          content: (
            <Fragment>
              {viewportLessThanMedium ? (
                <p>
                  This sidebar also contains links at the bottom of your screen.
                </p>
              ) : (
                ''
              )}
              <p>
                Under &quot;Help&quot;, you can find contact information and
                also show this tour again if you wish.
              </p>
            </Fragment>
          ),
        },
      ];

      // if default trading application does not use SSO:
      //  splice the trading credentials email step into the third position
      if (
        checkPermissions(user.uiViews, [
          `${grants.READ}:${subjects.TRADE_PROFILE}`,
        ])
      ) {
        if (!usesSSO(defaultTradingApp)) {
          steps.splice(2, 0, {
            target: '.main-holdings',
            title: 'Trading access',
            content: (
              <Fragment>
                <p>
                  Check your email inbox for a message titled &quot;Cboe Digital
                  Trading Credentials&quot;. If you don&apos;t see it, please
                  check your spam folder.
                </p>
                <p>
                  This email contains your unique username and allows you to set
                  a password which you&apos;ll use to access the{' '}
                  <a
                    href={get(env, `${defaultTradingApp}FrontendEndpoint`, '')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cboe Digital Trading Platform
                  </a>
                  . There are further links in the email to direct you.
                </p>
              </Fragment>
            ),
            placement: 'center',
          });
        } else {
          steps.splice(2, 0, {
            target: '#trade-actions-dropdown-button',
            title: 'Trading access',
            content: (
              <p>
                Click on &quot;Trade&quot; to access the trading platform. You
                will be asked to authenticate the application your first time.
              </p>
            ),
          });
        }
      }

      if (
        get(this.props, ['user', 'tutorialState']) !== 'pending_tutorial' &&
        this.props.showEStatementModal
      ) {
        this.props.showModal(TAX_STATEMENTS, { size: 'large' });
      }
      if (this.props.showOffboardingModal) {
        this.props.showModal(DM_OFFBOARDING_MODAL, { size: 'large' });
      }

      this.setState({
        clientId: get(env, `${defaultTradingApp}ClientId`),
        tradingFrontendEndpoint: get(
          env,
          `${defaultTradingApp}FrontendEndpoint`,
          '',
        ),
        steps,
      });
    });

  handleJoyrideCallback = ({ status, type }) => {
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      this.props.updateTutorialState({ state: 'done' });
    }
  };

  render = () => (
    <section className="main-holdings">
      <AccessControl
        allowedPermissions={[`${grants.READ}:${subjects.BALANCES}`]}
      >
        <AssetBalancesContainer
          accountIds={get(this.props, ['accounts'], []).map((a) =>
            get(a, 'accountId', ''),
          )}
          metadata={accountBalancesMetadata({
            showFutures: some(this.props.accounts, (account) =>
              accountIsAllowedFutures(account),
            ),
            showLossProfits: some(this.props.accounts, (account) =>
              accountIsAllowedSubExchange(account),
            ),
          })}
        />
      </AccessControl>
      <AccountsHomeHistoryContainer />
      {get(this.props, ['user', 'tutorialState']) === 'pending_tutorial' &&
        get(this.state, 'steps') && (
          <Joyride
            className="guided-tour"
            callback={this.handleJoyrideCallback}
            styles={joyrideTheme}
            steps={this.state.steps}
            continuous
            showSkipButton
            showProgress
            tooltipComponent={XTooltip}
          />
        )}
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeEMP);
