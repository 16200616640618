import React, { useEffect, useCallback, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Header, Icon, Form } from 'semantic-ui-react';
import get from 'lodash/get';
import head from 'lodash/head';
import { renderField, showModal } from 'erisxkit/client';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import history from '../../constants/history';
import colors from '../../constants/colors';
import { UISegment, sizes } from '../../styles/styled';
import AvailableTransferFunds from './AvailableTransferFunds';
import * as rules from '../../common/forms/rules';
import StyledButton from '../../common/StyledButton';
import {
  getTransferreeAccounts,
  getTransferrorAccounts,
  getAccountDescription,
} from '../../reducers/accountsReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import { getInternalTransferAssets } from '../../reducers/assetTypesReducer';
import {
  achWithdrawalLimit,
  getAchWithdrawalLimit,
} from '../../reducers/fundTransfersReducer';
import { TRANSFER_CONFIRMATION_MODAL } from '../../constants/modalTypes';
import AccountDropdown from '../../common/components/AccountDropdown';
import {
  INTERNAL_TRANSFERS_FORM,
  TRANSFER_FROM_ACCOUNT,
} from '../../constants/internalTransfersConstants';
import decimalPlacesOf from '../../utils/decimalPlacesOf';

const Wrapper = styled(UISegment)`
  margin: 2.2em auto 0 auto;
  max-width: 80%;
  @media (max-width: ${sizes.SMALL}) {
    max-width: 90%;
    padding: 1em 1em;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em 3em;
`;
Wrapper.displayName = 'Wrapper';

const InternalTransferHeader = styled.div`
  border-bottom: 2px solid ${colors.GREEN};
  margin: 15px auto 0px auto;
  color: ${colors.GREEN};
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 10px;
`;
InternalTransferHeader.displayName = 'InternalTransferHeader';

const SubHeader = styled.div`
  font-size: 20px;
  text-align: left;
  width: 100%;
  margin: 15px 0px 10px 0px;
`;

const Label = styled.span`
  color: ${colors.WHITE};
  font-weight: bold;
`;

const Account = styled.span`
  color: ${colors.GRAY_1};
`;

const Accounts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & :nth-child(1) {
    margin-right: 5px !important;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px 20px 0px;
  & :nth-child(1) {
    margin-right: 10px !important;
  }
`;

const Disclaimer = styled.p`
  color: ${colors.WHITE};
  font-size: 14px;
  text-align: left;
  width: 100%;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;
StyledForm.displayName = 'StyledForm';

const StyledField = styled(Field)`
  flex: 1 1 0;
`;
StyledField.displayName = 'StyledField';

const TradeButton = styled(StyledButton)`
  width: 190px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AccountLabel = ({ type }) => (
  <p>
    <Label>{type}</Label>
    <Account>Clearing Account </Account>
    <span style={{ color: 'red', fontSize: '14px' }}>*</span>
  </p>
);

const AmountField = styled(Field)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const getFundsDesignationLabel = (allowFutures) =>
  allowFutures ? 'Member Property' : 'Non-Segregated';

// label in parenthesis
// getFundsDesignationLabel(acc.allowFutures)
const parseAccounts = (accounts) =>
  accounts.map((acc) => ({
    key: get(acc, 'label', ''),
    text: `${get(acc, 'name', '')} (${get(acc, 'label', '')})`,
    // label: get(acc, 'label', ''),
    label: getAccountDescription(acc),
    value: {
      name: get(acc, 'name', ''),
      label: get(acc, 'label', ''),
      accountId: get(acc, 'accountId', ''),
      allowFutures: get(acc, 'allowFutures', false),
      allowSpot: get(acc, 'allowSpot', false),
      subAccountCode: get(acc, 'subAccountCode', ''),
      iraAcctType: get(acc, 'iraAcctType', ''),
      fundsDesignation: get(acc, 'fundsDesignation', null),
    },
  }));

const MAX_DECIMAL_PLACES = 2;

const InternalTransfers = ({ valid }) => {
  const dispatch = useDispatch();
  const [transactionRequestId, setTransactionRequestId] = useState(uuidv4());
  const amountLimit = useSelector(getAchWithdrawalLimit);
  const fromAccounts = parseAccounts(useSelector(getTransferrorAccounts));
  const toAccounts = parseAccounts(useSelector(getTransferreeAccounts));
  const formSelector = formValueSelector(INTERNAL_TRANSFERS_FORM);
  const fromAccount = useSelector((state) =>
    formSelector(state, TRANSFER_FROM_ACCOUNT),
  );

  const generateNewTransactionRequestId = () =>
    setTransactionRequestId(uuidv4());

  const memoizedLimitRule = useCallback(
    (values) => rules.transferLimitExceeded(values, amountLimit.available),
    [amountLimit.available],
  );

  const handleConfirm = () => {
    generateNewTransactionRequestId();
  };

  const onRequestTransfer = () =>
    dispatch(
      showModal(TRANSFER_CONFIRMATION_MODAL, {
        size: 'mini',
        handleConfirm,
        transactionRequestId,
      }),
    );

  useEffect(() => {
    if (fromAccount) {
      const { accountId, fundsDesignation } = fromAccount;
      dispatch(
        achWithdrawalLimit({ accountId, assetType: 'USD', fundsDesignation }),
      );
    }
  }, [fromAccount]);

  return (
    <>
      <Header
        size="huge"
        className="primary left-padded"
        style={{ marginTop: '30px' }}
      >
        <Header.Subheader
          className="back-link"
          onClick={() => history.goBack()}
        >
          <Icon name="left arrow" />
          Back
        </Header.Subheader>
        Internal Transfers
      </Header>
      <Wrapper>
        <InternalTransferHeader>INTERNAL TRANSFER</InternalTransferHeader>
        <SubHeader>Transfer Request</SubHeader>
        <AvailableTransferFunds amountLimit={amountLimit} />
        <StyledForm>
          <Accounts>
            <StyledField
              component={AccountDropdown}
              required
              label={<AccountLabel type="FROM" />}
              name="fromAccount"
              validate={[rules.required]}
              props={{
                accounts: fromAccounts.map((acc) => acc.value),
                id: 'internal-transfers-from',
              }}
            />
            <StyledField
              component={AccountDropdown}
              required
              label={<AccountLabel type="TO" />}
              name="toAccount"
              validate={[rules.required]}
              props={{
                accounts: toAccounts.map((acc) => acc.value),
                id: 'internal-transfers-to',
              }}
            />
          </Accounts>
          <Fields>
            <AmountField
              component={renderField}
              name="amount"
              required
              label="Amount"
              placeholder="Enter an amount"
              type="number"
              onChange={(event, amount) => {
                if (decimalPlacesOf(Number(amount)) > MAX_DECIMAL_PLACES) {
                  event.preventDefault();
                }
              }}
              validate={[rules.required, memoizedLimitRule]}
            />
            <Field
              component={renderField}
              name="asset"
              required
              label="Asset"
              validate={[rules.required]}
              onChange={(e) => e.preventDefault()}
            />
          </Fields>
        </StyledForm>
        <Disclaimer>
          Cboe Clear has a regulatory responsibility to segregate custom funds
          and must make sure funds are held in the correct custodian account
          before they are used. &nbsp;
        </Disclaimer>
        <Disclaimer>
          All Transfer requests are sent for approval and processed M-F during
          the hours of 7am and 6pm CT.
        </Disclaimer>
        <TradeButton
          text="Request Transfer"
          primary
          onClick={onRequestTransfer}
          disabled={!valid}
        />
      </Wrapper>
    </>
  );
};

const InternalTransferForm = reduxForm({
  form: INTERNAL_TRANSFERS_FORM,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InternalTransfers);

export default connect((state) => {
  const currentAcc = getActiveAccount(state);
  const fromAccounts = getTransferrorAccounts(state);
  const toAccounts = getTransferreeAccounts(state);

  const fromAccount = currentAcc
    ? get(
        head(
          parseAccounts(fromAccounts).filter(
            (acc) => acc?.value?.accountId === currentAcc.accountId,
          ),
        ),
        'value',
        '',
      )
    : '';

  const toAccount =
    currentAcc && toAccounts?.length === 1
      ? get(head(parseAccounts(toAccounts)), 'value', '')
      : undefined;

  return {
    initialValues: {
      asset: get(head(getInternalTransferAssets(state)), 'value', ''),
      fromAccount,
      toAccount,
    },
  };
})(InternalTransferForm);
