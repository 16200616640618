import React from 'react';
import cogoToast from 'cogo-toast';
import get from 'lodash/get';

const onPlaidError = (error) => {
  let close = null;
  close = cogoToast.error(
    <>
      <div style={{ marginBottom: '10px' }}>
        Unfortunately communication with your bank could not be completed.
        Please provide the information below to clearus.membership@cboe.com
      </div>
      <div>{get(error, 'response.data.error', 'Error data not available')}</div>
    </>,
    {
      hideAfter: 0,
      onClick: () => close(),
    },
  );
};

export default onPlaidError;
