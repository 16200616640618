import _ from 'lodash';
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Sidebar } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from 'erisxkit/client';
import {
  isMobileOnly,
  isConsole,
  isSmartTV,
  isWearable,
} from 'react-device-detect';
import styled from 'styled-components';
import classnames from 'classnames';
import LoadingComponent from '../common/components/xtable/LoadingComponent';
import { initGA, pageView as pageViewTracking } from '../common/tracking';
import SidebarContainer from '../containers/SidebarContainer';
import VerticalNavContainer from './containers/VerticalNavContainer';
import TopNavContainer from '../containers/TopNavContainer/TopNavContainer';
import ModalContainer from '../containers/ModalContainer';
import InitialDataContainer from '../containers/InitialDataContainer';
import { getLoggedInUser } from '../reducers/userReducer';
import ErrorMessage from './ErrorMessage';
import EnvBanner from './components/EnvBanner';
import EnvPromise from '../config/env';
import { isViewportLessThan } from '../reducers/uiReducer';
import IconsBG from '../common/components/IconsBG';
import ErrorPage from './components/ErrorPage';
import { isOnboarding } from '../utils/methods';
import { CHECK_SESSION_INTERVAL } from '../constants/timeout';
import NotifBanner from './components/NotifBanner';
import history from '../constants/history';
import { getAllMemberAccountIds } from '../reducers/accountsReducer';
import { getActiveAccountId } from '../reducers/activeAccountSelectors';
import {
  getSupportedBrowsersForCurrentOS,
  getUserBrowserOsCompatibility,
  isCurrentUserSessionUnsupported,
} from '../utils/isSupportedBrowserOS';
import colors from '../constants/colors';
import { getEnvPromiseResolved } from '../reducers/envReducer';

const loadingMessage = (
  accountsLoading,
  userLoading,
  userError,
  envPromiseResolved,
) => {
  if ((userLoading || !envPromiseResolved) && !accountsLoading && !userError) {
    return '';
  } else if (accountsLoading) {
    return {
      header: 'Loading accounts...',
    };
  }

  return {
    negative: true,
    header: _.get(userError, 'statusText', ''),
    text: _.get(userError, ['data', 'error'], ''),
  };
};

const errorPage = (
  <ErrorPage
    icon="warning circle"
    header="Not Supported Right Now"
    subheader={
      <Fragment>
        <p>
          We don&apos;t currently support on-boarding using this browser. Please
          go to{' '}
          <a href="https://clearing.erisx.com">https://clearing.erisx.com</a>{' '}
          from your PC or laptop computer to begin on-boarding.
        </p>
        <p>
          After the on-boarding process is complete, please continue to enjoy
          access to your Member Portal from your phone, tablet, PC, or laptop.
        </p>
      </Fragment>
    }
  />
);

const multiFAMessage = () => (
  <div>
    {!isMobileOnly ? (
      <Fragment>
        <Icon name="bell outline" />
        Two-factor authentication (2FA) is a security best practice to deter
        hackers.{' '}
      </Fragment>
    ) : (
      ''
    )}
    Cboe Clear recommends you enable 2FA.{' '}
    <Button
      onClick={() => history.push('/profile')}
      compact
      secondary
      content="Enable 2FA"
      id="mfa-button"
    />
  </div>
);

const unsupportedBrowserOSBanner = () => {
  // If this component is being rendered, then we know that either the browser or the os are not supported
  // So we can only check if the os is not supported. If it is, then the browser it's not supported and viceversa
  const { osSupported } = getUserBrowserOsCompatibility();
  const text = osSupported ? (
    `You are currently using an unsupported browser for your OS. We recommend using: ${getSupportedBrowsersForCurrentOS().join(
      ', ',
    )}`
  ) : (
    <span>
      You are using an unsupported OS. Please see our list of supported
      operating systems
      <a href="https://www.erisx.com/" target="_blank" rel="noreferrer">
        here
      </a>
    </span>
  );

  return (
    <div>
      {!isMobileOnly ? (
        <Fragment>
          <Icon name="exclamation circle" />
          {text}
        </Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

const bannerContent = (showMFA, showUnsupported) => (
  <>
    {showMFA && multiFAMessage()}
    {showUnsupported && unsupportedBrowserOSBanner()}
  </>
);

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  grid-area: content;
  overflow: hidden;
`;

const Content = styled.div`
  // Defaults to 'auto' which makes flex items grow as tall as their contents.
  // Must be set to 0 so content is entirely scrollable and screen is not cut.
  min-height: 0;
  // If it has additional (vertical) space, then it uses it (e.g. onboarding, there's no topnav)
  flex-grow: 1;
`;

const StyledArticle = styled.article`
  background-color: ${({ theme }) => theme.body};
`;
// Common layout of app -- Header, Navigation, Footer, etc. goes here.
class AppLayout extends PureComponent {
  state = {
    envBanner: '',
    onboarding: isOnboarding(_.get(this.props, ['user', 'state'])),
  };

  componentWillMount = () =>
    EnvPromise.then(
      ({
        envBanner = '',
        enableApiCredentials,
        enableMobileOnboarding,
        rsbixFrontendEndpoint,
      }) => {
        this.setState({
          envBanner,
          enableApiCredentials,
          enableMobileOnboarding,
          rsbixFrontendEndpoint,
        });
        pageViewTracking();
      },
    );

  componentDidMount() {
    // get a new access token every 15 minutes
    this.interval = setInterval(
      _.get(this.props, ['auth', 'checkSession']),
      CHECK_SESSION_INTERVAL,
    );
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      _.get(nextProps, ['user', 'state']) !==
      _.get(this.props, ['user', 'state'])
    ) {
      this.setState({
        onboarding: isOnboarding(_.get(nextProps, ['user', 'state'])),
      });
    }
  };

  componentWillUnmount() {
    // Clear the interval right before component unmount
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  // check props to add 'nav-closed' class to grid-container
  isNavClosed = () => {
    const {
      viewportLessThanMedium,
      hideVerticalNav,
      userLoading,
      accountsLoading,
    } = this.props;
    return (
      viewportLessThanMedium ||
      hideVerticalNav ||
      userLoading ||
      accountsLoading
    );
  };

  isSupportedDevice = () => {
    if (this.state.enableMobileOnboarding === 'true') {
      return true;
    }

    return !(isMobileOnly || isConsole || isSmartTV || isWearable);
  };

  renderVerticalNav = () =>
    !this.props.hideVerticalNav ? <VerticalNavContainer /> : null;

  showNotificationBanners = () => {
    const { user } = this.props;
    const showMFANotifBanner =
      _.get(user, 'auth', undefined) &&
      !_.get(user, ['auth', 'userMetadata', 'useMfa'], false) &&
      !this.state.onboarding;
    const showUnsupportedNotifBanner = isCurrentUserSessionUnsupported();
    return {
      showMFANotifBanner,
      showUnsupportedNotifBanner,
      showBanner: showMFANotifBanner || showUnsupportedNotifBanner,
    };
  };

  renderAppContent = () => {
    const {
      accountsLoading,
      user,
      userLoading,
      userError,
      children,
      accountIds,
      activeAccountId,
      envPromiseResolved,
    } = this.props;

    const { showBanner } = this.showNotificationBanners();

    return user.userId && !accountsLoading && envPromiseResolved ? (
      <Fragment>
        <ModalContainer />
        {this.renderVerticalNav()}
        {this.state.onboarding ||
        _.get(children, ['type', 'displayName']) === 'WelcomeContainer' ? (
          <IconsBG />
        ) : null}
        <ContentWrapper>
          <TopNavContainer
            auth={this.props.auth}
            simpleNav={this.state.onboarding}
            enableApiCredentials={this.state.enableApiCredentials}
            loading={accountsLoading || userLoading}
            accountIds={accountIds}
            activeAccountId={activeAccountId}
          />
          <Content>
            <Sidebar.Pushable
              className={classnames({
                withSidebar: !!this.props.sidebarContent,
              })}
            >
              <SidebarContainer />
              <Sidebar.Pusher dimmed={!!this.props.sidebarContent}>
                {this.state.onboarding && !this.isSupportedDevice()
                  ? errorPage
                  : children}
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Content>
        </ContentWrapper>
      </Fragment>
    ) : (
      <Fragment>
        <section className="content-container loading-screen">
          <LoadingComponent
            className="logo-loader-center full-height"
            size="200"
            disabled={this.props.userError}
            message={loadingMessage(
              this.props.accountsLoading,
              this.props.userLoading,
              this.props.userError,
              this.props.envPromiseResolved,
            )}
          />
        </section>
      </Fragment>
    );
  };

  render = () => {
    const { showMFANotifBanner, showUnsupportedNotifBanner } =
      this.showNotificationBanners();

    return (
      <StyledArticle
        onboarding={isOnboarding(_.get(this.props, ['user', 'state'], ''))}
        className={`grid-container${this.isNavClosed() ? ' nav-closed' : ''}`}
      >
        {_.isEmpty(this.state.envBanner) ? null : (
          <EnvBanner text={this.state.envBanner} />
        )}
        {_.get(this.props.user, 'auth', undefined) &&
        !_.get(this.props.user, ['auth', 'userMetadata', 'useMfa'], false) &&
        !this.state.onboarding ? (
          <NotifBanner
            component={() =>
              bannerContent(showMFANotifBanner, showUnsupportedNotifBanner)
            }
          />
        ) : null}
        <InitialDataContainer
          rsbixFrontend={this.state.rsbixFrontendEndpoint}
          auth={this.props.auth}
        />
        <ErrorMessage />
        {this.renderAppContent()}
      </StyledArticle>
    );
  };
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideVerticalNav: PropTypes.bool,
  userLoading: PropTypes.bool,
  userError: PropTypes.any,
  viewportLessThanMedium: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

AppLayout.defaultProps = {
  hideVerticalNav: false,
  userLoading: false,
  userError: {},
};

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
  userLoading: createLoadingSelector(['USER', 'PII', 'ACCOUNTS'])(state),
  userError: createErrorMessageSelector(['USER', 'PII', 'ACCOUNTS'])(state),
  accountsLoading: createLoadingSelector(['ACCOUNTS'])(state),
  viewportLessThanMedium: isViewportLessThan('medium', state),
  accountIds: getAllMemberAccountIds(state),
  activeAccountId: getActiveAccountId(state),
  sidebarContent: _.get(state, ['ui', 'sidebar', 'sidebarContent']),
  envPromiseResolved: getEnvPromiseResolved(state),
});

export default connect(mapStateToProps)(AppLayout);
