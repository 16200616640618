import * as colors from './colorPalettes';
import { DefaultTheme } from 'styled-components';

export const darkTheme: DefaultTheme = {
  body: colors.dark.primary,
  accent: colors.dark.accent,
  accentNegative: colors.dark.tradingSell,
  text: {
    header: colors.dark.secondary,
    subheader: colors.dark.secondary3,
    text: colors.dark.secondary2,
  },
  topnav: {
    active: colors.dark.accent,
    bg: colors.dark.primary,
    color: colors.dark.secondary,
  },
  sidenav: {
    bg: colors.dark.primaryCboeClear,
    active: colors.dark.accent,
    color: colors.dark.secondary,
    boxShadow: 'none',
    height: 'calc(100% - 55px)',
  },
  footer: {
    bg: colors.dark.primary,
    text: colors.dark.secondary2,
    link: colors.dark.accent2,
    icon: colors.dark.secondary2,
  },
  loading: colors.dark.secondary,
  modal: {
    dimmer: 'rgba(12, 55, 90, 0.75)',
    bg: colors.dark.primary,
  },
  form: {
    field: {
      label: {
        color: colors.dark.secondary,
      },
      input: {
        color: colors.dark.secondary,
        placeholder: colors.dark.secondary2,
        activeUnderline: colors.dark.accent,
      },
      dropdown: {
        color: colors.dark.secondary,
        item: {
          bg: colors.dark.secondary,
          hover: colors.dark.accent,
          color: colors.dark.primary,
        },
      },
    },
  },
  table: {
    header: {
      color: colors.dark.secondary2,
      bg: colors.dark.primary3,
      border: colors.dark.primary4,
      hover: colors.dark.primary3,
      fontWeight: 'bold',
    },
    row: {
      bg: colors.dark.primary2,
      evenRowBg: colors.dark.primary2,
      color: colors.dark.secondary,
      bottomBorder: colors.dark.primary4,
      hover: colors.dark.primary8,
    },
    resizer: colors.dark.primary,
    noDataText: colors.dark.secondary,
    input: {
      bg: colors.dark.primary,
      color: colors.dark.accent,
      border: 'none',
    },
    select: {
      bg: colors.dark.primary,
      color: colors.dark.secondary2,
      border: 'none',
      hover: colors.dark.primary3,
    },
    edit: {
      dismiss: colors.dark.secondary,
      disclaimer: {
        bg: colors.dark.primary3,
        border: colors.dark.primary3,
        color: colors.dark.secondary,
      },
    },
    loader: {
      spinner: colors.dark.accent4,
    },
    filter: {
      bg: colors.dark.primary,
      separator: colors.dark.primary9,
    },
    footer: {
      bg: colors.dark.primary2,
    },
    quickActions: {
      color: colors.dark.secondary,
      menu: {
        bg: colors.dark.primary2,
        bgHover: colors.dark.accent,
        color: colors.dark.secondary2,
      },
    },
    scrollbar: colors.dark.accent,
    accent: colors.dark.accent,
    delete: colors.dark.tradingSell,
  },
  button: {
    primary: {
      borderColor: colors.dark.accent,
      borderColorDisabled: colors.dark.accent2,
      bgColor: colors.dark.accent,
      bgColorDisabled: colors.dark.accent2,
      bgColorHover: colors.dark.accent3,
      bgColorActive: colors.dark.accent4,
      color: colors.dark.primary,
      colorDisabled: colors.dark.accent5,
    },
    secondary: {
      borderColor: colors.dark.accent,
      borderColorDisabled: colors.dark.secondary3,
      bgColor: colors.dark.primary,
      bgColorDisabled: colors.dark.secondary3,
      bgColorHover: colors.dark.primary6,
      bgColorActive: colors.dark.primary5,
      color: colors.dark.accent,
      colorDisabled: colors.dark.secondary,
    },
    danger: {
      borderColor: colors.dark.accentNegative,
      borderColorDisabled: colors.dark.secondary3,
      bgColor: colors.dark.accentNegative,
      bgColorDisabled: colors.dark.secondary3,
      bgColorHover: colors.dark.accentNegative2,
      bgColorActive: colors.dark.accentNegative2,
      color: colors.dark.secondary,
      colorDisabled: colors.dark.secondary,
    },
    sell: {
      borderColor: colors.dark.tradingSell,
      borderColorDisabled: colors.dark.tradingSell2,
      bgColor: colors.dark.tradingSell,
      bgColorDisabled: colors.dark.tradingSell2,
      bgColorHover: colors.dark.tradingSell4,
      bgColorActive: colors.dark.tradingSell3,
      color: colors.dark.secondary2,
      colorDisabled: colors.dark.secondary,
    },
  },
  infoTooltip: colors.dark.secondary2,
  ui: {
    remove: colors.dark.tradingSell,
    header: colors.dark.secondary,
    subheader: colors.dark.secondary3,
    textButtons: {
      separator: colors.dark.secondary,
    },
    checkbox: {
      border: `none`,
      checked: colors.dark.accent,
    },
    segment: {
      bg: colors.dark.primary2,
      separator: colors.dark.primary9,
    },
    labeledField: {
      label: colors.dark.secondary3,
      field: colors.dark.secondary2,
    },
    sidebar: {
      bg: colors.dark.primary,
      content: colors.dark.secondary,
    },
  },
  userDropdown: {
    menu: {
      bg: '',
    },
  },
  link: {
    color: colors.dark.accent,
    colorHover: colors.dark.accent8,
    colorPrimary: colors.dark.accent,
    underline: 'none',
    hoverUnderline: `0px 2px 0 0 ${colors.dark.accent8}`,
    colorActive: colors.dark.accent7,
    activeUnderline: `0px 1px 0 0px ${colors.dark.accent8}`,
    primaryHover: colors.dark.accent8,
  },
  onboarding: {
    content: {
      border: `1px solid ${colors.dark.secondary4}`,
    },
    steps: {
      color: colors.dark.secondary,
      colorActive: colors.dark.secondary,
    },
    funding: {
      minReqs: {
        color: colors.dark.primary3,
      },
    },
  },
  accountDropdown: {
    option: {
      bg: colors.dark.primary2,
      bgHover: colors.dark.accent,
      accountNameColor: colors.dark.secondary,
      accountNameColorSelected: colors.dark.secondary4,
      accountLabelColor: '',
      accountNameColorHover: colors.dark.secondary4,
      accountLabelColorHover: '',
    },
    typeChip: {
      bg: colors.dark.accent,
      bgSelected: colors.dark.primary,
      color: colors.dark.primary,
      colorSelected: colors.dark.secondary2,
    },
  },
  tabs: {
    bg: colors.dark.primary3,
    hoverAccent: colors.dark.accent,
    text: colors.dark.secondary,
    textActive: colors.dark.accent,
    tabSeparator: 'rgba(34, 36, 38, 0.15)',
    border: colors.dark.primary3,
    bgActive: colors.dark.primary4,
  },
};

export const lightTheme: DefaultTheme = {
  body: colors.light.primary,
  accent: colors.light.accent2,
  accentNegative: colors.light.accentNegative,
  text: {
    header: colors.light.secondary,
    subheader: colors.light.primary10,
    text: colors.light.primary12,
  },
  topnav: {
    bg: colors.light.primary2,
    active: colors.light.accent,
    color: colors.light.secondary2,
    boxShadow: '0px 1px 4px rgb(0 0 0 / 10%)',
  },
  sidenav: {
    bg: colors.light.primary2,
    active: colors.light.accent2,
    color: colors.light.secondary,
    height: '100%',
  },
  footer: {
    bg: colors.light.primary,
    text: colors.light.secondary2,
    link: colors.light.accent2,
    icon: colors.light.secondary2,
  },
  loading: colors.light.secondary2,
  modal: {
    dimmer: 'rgb(154 168 180 / 47%)',
    bg: colors.light.primary2,
  },
  form: {
    field: {
      label: {
        color: colors.light.primary3,
      },
      input: {
        color: colors.light.primary7,
        placeholder: colors.light.primary11,
        activeUnderline: colors.light.accent4,
      },
      dropdown: {
        color: colors.light.primary11,
        item: {
          bg: colors.light.primary2,
          hover: colors.light.accent9,
          color: colors.light.primary7,
        },
      },
    },
  },
  table: {
    header: {
      color: colors.light.black,
      bg: colors.light.primary2,
      border: colors.light.primary2,
      hover: colors.light.primary2,
      fontWeight: '400',
    },
    row: {
      bg: colors.light.primary5,
      evenRowBg: colors.light.primary2,
      color: colors.light.primary7,
      bottomBorder: colors.light.primary5,
      hover: colors.light.secondary5,
    },
    resizer: colors.light.primary6,
    noDataText: colors.light.secondary2,
    input: {
      bg: colors.light.primary2,
      color: colors.light.primary7,
      border: `1px solid ${colors.light.primary4}`,
    },
    select: {
      bg: colors.light.primary2,
      color: colors.light.primary7,
      border: `1px solid ${colors.light.primary4}`,
      hover: colors.light.primary,
    },
    edit: {
      dismiss: colors.light.secondary,
      disclaimer: {
        bg: colors.light.primary2,
        border: colors.light.primary6,
        color: colors.light.primary7,
      },
    },
    loader: {
      spinner: colors.light.secondary4,
    },
    filter: {
      bg: colors.light.primary2,
      separator: colors.light.primary6,
    },
    footer: {
      bg: colors.light.primary2,
      text: colors.light.primary7,
    },
    quickActions: {
      color: colors.light.secondary2,
      menu: {
        bg: colors.light.primary2,
        bgHover: colors.light.accent10,
        color: colors.light.secondary2,
      },
    },
    scrollbar: colors.light.secondary3,
    accent: colors.light.accent2,
    delete: colors.light.accentNegative6,
  },
  button: {
    primary: {
      borderColor: colors.light.accent2,
      borderColorDisabled: colors.light.accent6,
      bgColor: colors.light.accent2,
      bgColorDisabled: colors.light.accent6,
      bgColorHover: colors.light.accent3,
      bgColorActive: colors.light.accent4,
      color: colors.light.primary2,
      colorDisabled: colors.light.accent7,
    },
    secondary: {
      borderColor: colors.light.secondary3,
      borderColorDisabled: colors.light.primary4,
      bgColor: colors.light.primary2,
      bgColorDisabled: colors.light.primary6,
      bgColorHover: colors.light.primary9,
      bgColorActive: colors.light.primary6,
      color: colors.light.secondary3,
      colorDisabled: colors.light.primary8,
    },
    danger: {
      borderColor: colors.light.accentNegative,
      borderColorDisabled: colors.light.accentNegative4,
      bgColor: colors.light.accentNegative,
      bgColorDisabled: colors.light.accentNegative4,
      bgColorHover: colors.light.accentNegative2,
      bgColorActive: colors.light.accentNegative3,
      color: colors.light.primary,
      colorDisabled: colors.light.accentNegative5,
    },
    sell: {
      borderColor: colors.dark.tradingSell,
      borderColorDisabled: colors.dark.tradingSell2,
      bgColor: colors.dark.tradingSell,
      bgColorDisabled: colors.dark.tradingSell2,
      bgColorHover: colors.light.accentNegative2,
      bgColorActive: colors.dark.tradingSell3,
      color: colors.dark.secondary2,
      colorDisabled: colors.dark.secondary,
    },
  },
  infoTooltip: colors.light.secondary,
  ui: {
    remove: colors.light.accentNegative6,
    header: colors.light.secondary,
    subheader: colors.light.primary7,
    textButtons: {
      separator: colors.light.primary6,
    },
    // StyledCheckbox
    checkbox: {
      border: `1px solid ${colors.light.primary4}`,
      checked: colors.light.accent2,
    },
    segment: {
      bg: colors.light.primary2,
      separator: colors.light.primary6,
    },
    labeledField: {
      label: colors.light.secondary,
      field: colors.light.primary3,
    },
    sidebar: {
      bg: colors.light.primary2,
      content: 'inherit',
    },
  },
  userDropdown: {
    menu: {
      bg: colors.light.primary2,
    },
  },
  link: {
    color: colors.light.accent2,
    colorHover: colors.light.accent8,
    colorPrimary: colors.light.accent,
    colorActive: colors.light.accent4,
    underline: 'none',
    hoverUnderline: `0px 1px 0 0px ${colors.light.accent8}`,
    activeUnderline: `0px 1px 0 0px ${colors.light.accent4}`,
    primaryHover: colors.light.accent8,
  },
  onboarding: {
    content: {
      border: 'none',
    },
    steps: {
      color: colors.light.secondary,
      colorActive: colors.light.accent2,
    },
    funding: {
      minReqs: {
        color: colors.light.secondary6,
      },
    },
  },
  accountDropdown: {
    option: {
      bg: colors.light.primary,
      bgHover: colors.light.accent10,
      accountNameColor: colors.light.secondary,
      accountNameColorSelected: colors.light.secondary,
      accountLabelColor: '',
      accountNameColorHover: colors.light.secondary,
      accountLabelColorHover: '',
    },
    typeChip: {
      bg: colors.light.secondary,
      bgSelected: colors.light.secondary,
      color: colors.light.primary,
      colorSelected: colors.light.primary,
    },
  },
  tabs: {
    bg: colors.light.primary2,
    hoverAccent: colors.light.accent2,
    text: colors.light.black2,
    textActive: colors.light.accent2,
    bgActive: colors.light.primary15,
    tabSeparator: 'rgba(34, 36, 38, 0.15)',
    border: colors.light.primary14,
  },
};
