import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal, Icon } from 'semantic-ui-react';
import StyledButton from '../../common/StyledButton';
import { hideModal } from 'erisxkit/client';

const Continue = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
`;

const UnregisteredUserModal = ({ email }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal.Content scrolling>
        <Title>User is not registered with Cboe Clear</Title>
        <SubTitle>
          Please contact {email} to have them registered before you can add
          them.
        </SubTitle>
      </Modal.Content>
      <Modal.Actions>
        <Continue onClick={() => dispatch(hideModal())} text="Continue" />
      </Modal.Actions>
    </>
  );
};

export default UnregisteredUserModal;
