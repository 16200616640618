import React from 'react';
import { Segment, Header, List } from 'semantic-ui-react';

const Contact = () => (
  <section className="main-holdings">
    <Header as="h1" icon="mail">
      Contact
    </Header>
    <Segment padded>
      <p>For questions relating to:</p>
      <List bulleted>
        <List.Item>
          <b>Member account updates</b>, contact{' '}
          <a
            href="mailto:clearus.membership@cboe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            clearus.membership@cboe.com
          </a>
          .
        </List.Item>
        <List.Item>
          <b>Cleared trade inquiries</b>, contact{' '}
          <a
            href="mailto:clearus.operations@cboe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            clearus.operations@cboe.com
          </a>
          .
        </List.Item>
      </List>
    </Segment>
  </section>
);

export default Contact;
