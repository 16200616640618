import React from 'react';
import { Grid, GridColumn, GridRow, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { FOOTER_HEIGHT, DARK_NAVY } from '../../constants/layout';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.body};
`;

const StyledColumn = styled(GridColumn)`
  color: ${({ theme }) => theme.text.header};
`;

const StyledAnchor = styled.a`
  text-decoration: 'none';
  color: ${({ theme }) => theme.footer.icon};
  box-shadow: 'none';
  &:hover {
    text-decoration: 'none';
    color: ${({ theme }) => theme.footer.icon};
    box-shadow: 'none';
  }
`;
const styles = {
  container: {
    height: FOOTER_HEIGHT,
    // this will pin the footer at the bottom of the page
    // but not have it cover anything if the screen is
    // too small
    position: 'absolute',
    bottom: 0,
    padding: '1rem',
    fontSize: '8pt',
    width: '100vw',
  },
  social: {
    textDecoration: 'none',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
      boxShadow: 'none',
    },
  },
};

function Footer() {
  return (
    <Wrapper style={styles.container}>
      <Grid columns={2}>
        <GridRow>
          <StyledColumn width={10}>
            <strong>&copy; {new Date().getFullYear()} – Cboe Clear</strong>
            <br />
            Chicago, IL 60606
            <br />
            To see our state and federal licenses click{' '}
            <a
              href="https://www.cboedigital.com/regulation/licenses/"
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </StyledColumn>
          <GridColumn width={6} textAlign="right">
            <a
              href="mailto:clearus.membership@cboe.com"
              target="_blank"
              rel="noreferrer"
            >
              clearus.membership@cboe.com
            </a>
          </GridColumn>
        </GridRow>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
